/* margins */
.m-0 {
  margin: 0px;
}

.m-t-a {
  margin-top: auto;
}
.m-b-a {
  margin-bottom: auto;
}
.m-r-a {
  margin-right: auto;
}
.m-l-a {
  margin-left: auto;
}

m-t-0 {
  margin-top: 0px;
}
m-t-1 {
  margin-top: 1rem;
}
m-t-2 {
  margin-top: 2rem;
}
m-t-3 {
  margin-top: 3rem;
}

m-b-0 {
  margin-bottom: 0px;
}
m-b-1 {
  margin-bottom: 1rem;
}
m-b-2 {
  margin-bottom: 2rem;
}
m-b-3 {
  margin-bottom: 3rem;
}

m-r-0 {
  margin-right: 0px;
}
m-r-1 {
  margin-right: 1rem;
}
m-r-2 {
  margin-right: 2rem;
}
m-r-3 {
  margin-right: 3rem;
}

m-l-0 {
  margin-left: 0px;
}
m-l-1 {
  margin-left: 1rem;
}
m-l-2 {
  margin-left: 2rem;
}
m-l-3 {
  margin-left: 3rem;
}

/* display */
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}

/* width */
.w-0 {
  width: 0px;
}
.w-25 {
  width: 25%;
}
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
.w-100 {
  width: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-select-dropdown {
  z-index: 1400;
}

.rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
  width: 50% !important;
  border-radius: 0px !important;
}

/* scrollbar styles for firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #dde3e8 transparent !important;
}

/* scrollbar styles for rest of the browsers */

*::-webkit-scrollbar {
  width: 23px;
  height: 23px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #dde3e8;
  border-radius: 40px;
  border: 8px solid #ffffff;
}

.form-builder::-webkit-scrollbar-thumb {
  border: 8px solid #ffffff;
  border-radius: 40px;
  width: 23px;
}

/* Change background for white color */
input:-webkit-autofill,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset, 0 0 0 2px rgba(2, 143, 223, 0.2) !important;
}

.hide-buttons button {
  visibility: hidden;
}

/* TODO: update ant so we can get rid of css styles below */

/* Ant TimePicker does not pass class to dropdown so we have to add styles here */
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: #ffffff;
}

/* Ant TimePicker does not pass class to dropdown so we have to add styles here */
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner:hover {
  color: #000000;
}

/* Ant TimePicker does not pass class to dropdown so we have to add styles here */
.ant-picker-dropdown .ant-picker-footer button {
  font-weight: bold;
}

/* Ant TimePicker does not pass class to dropdown so we have to add styles here */
.ant-picker-dropdown .ant-picker-footer .ant-picker-now-btn {
  color: #028fdf;
}

/* Ant TimePicker does not pass class to dropdown so we have to add styles here */
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  padding-left: 18px;
}

/* used for globally setting cursor when dragging elements */
.global-ew-resize-cursor {
  cursor: ew-resize !important;
}

.global-ew-resize-cursor * {
  cursor: ew-resize !important;
}
